(<template>
  <shared-pricing class="pt-applied">
    <applied-actions />
  </shared-pricing>
</template>)

<script>
  import SharedPricing from '@/components/assignment_components/one_assignment/interactive_info/shared_components/SharedPricing';
  import AppliedActions from '@/components/assignment_components/one_assignment/interactive_info/pt_actions/AppliedActions';

  export default {
    components: {
      'shared-pricing': SharedPricing,
      'applied-actions': AppliedActions
    }
  };
</script>
